@import url('https://fonts.googleapis.com/css?family=Roboto');

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?n9qceb');
  src: url('fonts/icomoon.eot?n9qceb#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?n9qceb') format('truetype'),
    url('fonts/icomoon.woff?n9qceb') format('woff'),
    url('fonts/icomoon.svg?n9qceb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caboose:before {
  content: "\e900";
}
.icon-diesel:before {
  content: "\e901";
}
.icon-electric:before {
  content: "\e902";
}
.icon-freight:before {
  content: "\e903";
}
.icon-passenger:before {
  content: "\e904";
}
.icon-steam:before {
  content: "\e905";
}
.icon-train:before {
  content: "\e906";
}
.icon-train-plus:before {
  content: "\e907";
}
.icon-train-set:before {
  content: "\e908";
}
.icon-train-set-plus:before {
  content: "\e909";
}

/* Global Styles */
:root {
  --primary-color: #dc3545;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 3000px;
  margin: auto;
  overflow: hidden;
  padding: 0 1rem;
}

/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 1rem 0;
}

/* Cards */
.card-gymmember {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0rem;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */
.p {
  padding: 0.5rem;
}

.p-indent {
  padding: 0.5rem;
  padding-left: 2rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-train-title {
  margin: 1rem 0 0 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mx-1 {
  margin-left: 1rem;
}

.logo {
  font-size: 0.1rem;
}

/* Grid */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.badge {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
  margin-bottom: 0.4rem;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */
input {
  margin: 1.2rem 0;
}

.text-area-aws-table {
  font-size: 15px;
}

.input-aws-table {
  margin: 0.2rem 0 0 0;
}

.input-aws-table-label {
  margin: 1rem 0 0 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type='submit'],
button {
  font: inherit;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
  margin-bottom: 1rem;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--light-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
  margin-bottom: 0rem;
}

.footer ul {
  display: flex;
}

.footer a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.footer a:hover {
  color: var(--light-color);
}

.footer .welcome span {
  margin-right: 0.6rem;
}

.home-page-top-margin {
  margin-top: 3rem;
}

.home-page-top-margin-normal {
  margin-top: 4rem;
}

.home-page-bottom-margin {
  margin-bottom: 4rem;
}

.hobbyist-page-top-margin {
  margin-top: 4rem;
}

.hobbyist-page-bottom-margin {
  margin-bottom: 5rem;
}

.alert-top-margin {
  margin-top: 5rem;
  margin-bottom: -3rem;
}

/* Mobile Styles */
@media (max-width: 1581) {
  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Mobile Styles */
@media (max-width: 1224px) {
  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Mobile Styles */
@media (max-width: 928px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  .home-page-top-margin {
    margin-top: 3rem;
  }

  .home-page-bottom-margin {
    margin-bottom: 4rem;
  }

  .gymmember-page-top-margin {
    margin-top: 5rem;
  }

  .gymmember-page-bottom-margin {
    margin-bottom: 5rem;
  }

  .alert-top-margin {
    margin-top: 7rem;
    margin-bottom: -4rem;
  }
}

/* Mobile Styles */
@media (max-width: 757px) {
  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  .home-page-top-margin {
    margin-top: 3rem;
  }

  .home-page-bottom-margin {
    margin-bottom: 3rem;
  }

  .hobbyist-page-top-margin {
    margin-top: 4rem;
  }

  .hobbyist-page-bottom-margin {
    margin-bottom: 5rem;
  }

  .alert-top-margin {
    margin-top: 8rem;
    margin-bottom: -5rem;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Mobile Styles */
@media (max-width: 443px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: repeat(1, 1fr);
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  .home-page-top-margin {
    margin-top: 3rem;
  }

  .home-page-bottom-margin {
    margin-bottom: 3rem;
  }

  .hobbyist-page-top-margin {
    margin-top: 4rem;
  }

  .hobbyist-page-bottom-margin {
    margin-bottom: 5rem;
  }

  .alert-top-margin {
    margin-top: 11rem;
    margin-bottom: -5rem;
  }
}

/* Mobile Styles */
@media (max-width: 399px) {
  .home-page-top-margin {
    margin-top: 9rem;
  }

  .home-page-bottom-margin {
    margin-bottom: 3rem;
  }

  .hobbyist-page-top-margin {
    margin-top: 6rem;
  }

  .hobbyist-page-bottom-margin {
    margin-bottom: 5rem;
  }
}

.display-linebreak {
  white-space: pre-line;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #383a42;
  background: #fafafa;
}

.hljs-comment,
.hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #a626a4;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e45649;
}

.hljs-literal {
  color: #0184bb;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #50a14f;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #c18401;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #986801;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #4078f2;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

.modal {
  font-size: 12px;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;
}

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
